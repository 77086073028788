import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import Typography from "@mui/material/Typography";
import {Button, Paper, Skeleton, Stack} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useApiHook from "../hooks/useApiHook";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import SpeedIcon from '@mui/icons-material/Speed';
import StarIcon from '@mui/icons-material/Star';

moment.locale('it');

const FileDetails = (props) => {
    const {response, makeRequest, error} = useApiHook();
    const {fileId } = useParams();
    const navigate = useNavigate();

    const [fileDetails, setFileDetails] = useState(null);
    const [transcriptions, setTranscriptions] = useState([]);

    const deleteBtnClickHandler = () => {
        makeRequest('file/delete', 'post', {fileId });
        navigate('/');
    }

    useEffect(() => {
       makeRequest(`file/${fileId}/details`);
    }, [])

    useEffect(() => {
        if (response && response.state === 'success') {
            response.data.formattedDate = moment(response.data.createdAt).format('D MMMM YYYY, HH:mm:ss');
            if (response.data.transcriptions.length > 0) {
                setTranscriptions([]);
                response.data.transcriptions.forEach((tr) => {
                    setTranscriptions( prevState => [...prevState, tr]);
                })

            }
            setFileDetails(response.data);
        }
    }, [response])

    const transcriptionsList =  transcriptions.map(tr => {
        const transcription = JSON.parse(tr.transcription);
        const sentences = transcription.map(sentence => {
            return <>
                <Stack my={2} direction="row" spacing={1}>
                    <Typography variant='body1' fontWeight='bold' color={tr.model === "nano" ? "primary" : "success"}>{sentence.speaker}: </Typography>
                    <Typography variant='body1'>{sentence.text}</Typography>
                </Stack>
            </>
        })
        const secondsTaken = Math.floor(tr.timeTaken / 1000);
        return <Stack direction="column" spacing={1}>
                <Paper sx={{p: 2}} elevation={2}>
                    <Stack direction="row" alignItems="center" spacing={1} >
                        {tr.model === 'nano' && <SpeedIcon color="primary" />}
                        {tr.model === 'best' && <StarIcon color="success" />}
                        <Typography variant="h5" color={tr.model === 'nano' ? "primary" : "success"} fontWeight="bold" >
                            {tr.model.toUpperCase()}
                        </Typography>
                    </Stack>
                    <Typography mt={1} variant="body1">
                        <b>Tempo impiegato: </b>
                        {secondsTaken > 59 && `${Math.floor(secondsTaken / 60)} min `}
                        {secondsTaken % 60} sec
                    </Typography>
                </Paper>
                <Paper elevetion={2}>
                    <Box p={2}>
                        {sentences}
                    </Box>
                </Paper>
            </Stack>
    });

    return <>
        { !fileDetails && <Skeleton variant='rectangular' height={42} ></Skeleton>}
        { fileDetails && <>
            <Stack spacing={2} direction="row" alignItems="center" >
                <LibraryBooksIcon fontSize="large" />
                <Typography variant='h4' fontWeight='bold' flexGrow={1}>{fileDetails.name}</Typography>
                <Button
                    size="large"
                    variant="contained"
                    color={"error"}
                    onClick={deleteBtnClickHandler}
                    startIcon={<DeleteForeverIcon />}>
                    Elimina File
                </Button>
            </Stack>
        </>}
        <Box my={1}>
            { !fileDetails && <Skeleton variant='rectangular' height={24} ></Skeleton>}
            { fileDetails && <Typography variant='body1'>
                {fileDetails.formattedDate}
            </Typography>}
        </Box>
        <Box my={3}>
            { (!transcriptions.length && !fileDetails) && <Skeleton variant='rectangular' height={150} ></Skeleton>}
            { (!transcriptions.length && fileDetails) && <Typography variant='h6'>Generazione testo in corso...</Typography> }
            { (transcriptions.length > 0 && fileDetails) && <Stack direction="row" spacing={3}>
                {transcriptionsList}
            </Stack>}
        </Box>
    </>;
}

export default FileDetails;