import {useState} from "react";
import axios from "axios";
import config from "../config.json";

const useApiHook = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);

    const makeRequest = async (url, method = 'get', requestData = {}) => {
        try {
            setUploadProgress(0);
            setLoading(true);
            const formData = new FormData();
            let isFile = false;
            Object.keys(requestData).forEach((key) => {
                if(key === 'audioFile')
                    isFile = true;
                formData.append(key, requestData[key]);
            });

            const resp = await axios({
                headers: {
                    "Content-Type": isFile ? "multipart/form-data" : "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                },
                url: config.server.host + url,
                method,
                data: formData,
                onUploadProgress: ({progress}) => {
                    setUploadProgress(+(progress * 100))
                }
            });
            setResponse(resp.data);
            setLoading(false);
        } catch (error) {
            // TODO: gestire mancanza token
            setError(error.response ? error.response.data : error);
            setLoading(false);

        }
    };

    return { makeRequest, response, uploadProgress, loading, error };
}

export default useApiHook;