import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress,
    Skeleton,
    Stack
} from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useApiHook from "../hooks/useApiHook";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import VisuallyHiddenInput from "./_atoms/VisuallyHiddenInput";
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import Box from "@mui/material/Box";

const FileUploadDialog = (props) => {
    const {response, makeRequest, loading, uploadProgress} = useApiHook();
    const [fileName, setFileName] = useState("")

    const uploadBtnClickHandler = (ev) => {
        setFileName( ev.target.files[0].name);
        makeRequest('file', 'post', {audioFile: ev.target.files[0]} );
    }

    useEffect(() => {
        if (response) {
            if (response.state === 'success') {
                props.onFileUpload();
                props.onClose();
            } else {
                // TODO: gestire errore
            }
        }
    }, [response]);

    useEffect(() => {
    }, [uploadProgress]);

    return <>
        <Dialog
            open={props.open}
            onClose={props.onClose}
            >
            <DialogTitle>Nuovo file</DialogTitle>
            <DialogContent>
                {!loading && <>
                    <DialogContentText>Aggiungi un nuovo file da trascrivere.
                        <br />Il processo di trascrizione inizierà una volta caricato il file
                    </DialogContentText>
                    <Stack mt={4} alignItems="center" justifyContent="center">
                        <Button
                            size={"large"}
                            component={'label'}
                            tabIndex={-1}
                            variant="contained"
                            color="primary"
                            startIcon={<FileUploadIcon />}
                        >
                            Seleziona file
                            <VisuallyHiddenInput
                                type="file"
                                accept="audio/mp3,audio/*"
                                onChange={uploadBtnClickHandler}
                            />
                        </Button>
                    </Stack>
                </>}
                {loading && <Stack alignItems="center" justifyContent="center">
                    <Typography variant="body1" mt={2} mb={2} component="div">Caricamento file in corso...</Typography>
                    <Stack direction="column" position="relative" spacing={0}>
                        <Box position="relative">
                            <Skeleton variant="rounded" sx={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}} width={100} height={110} />
                            <AudiotrackIcon sx={{fontSize: 40, position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }} />
                            <Typography variant="body2" position="absolute" bottom={0} left="50%" sx={{transform: "translateX(-50%)"}}>{parseInt(uploadProgress)}%</Typography>
                        </Box>
                        <LinearProgress variant="determinate" value={uploadProgress} />
                    </Stack>
                    <Typography pt={1} variant="body1">{fileName}</Typography>
                </Stack>}
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default FileUploadDialog;